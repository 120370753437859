.checkboxes {
  display: flex;
  flex-direction: column;
  text-align: left;
  input[type=checkbox] {
    margin-right: 21px;
    width: 21px; height: 21px;
  }
  textarea {
    width: 100%;
    min-height: 140px;
  }
}