.App {
  text-align: center;
}
.header {
  padding: 0 20%;

  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* 203.170.80.250 */
.title{
  margin-bottom: 4px;
}
.sub-title{
  margin-top: 4px;
}
.description {
  color: #eee
}
a {
  color: white
}


@media only screen and (max-width: 600px) {
  .header {
    padding: 0 16px;
  }
}
